.App {
  background-color: #fff;
  max-width: 100vw;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  overflow-x: hidden;
}

.App-TopBar {
  top: 0;
}

 .App-Body {
  max-width: 100vw;
}

.App-Footer {
  max-width: 100vw;
}


