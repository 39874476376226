.profile-container {
    display: flex;
    margin-bottom: 8vh;
    justify-content: center;
    align-items: center;
    max-width: 80vw;
    flex-direction: row;
  }
  
  .left-column {
    flex: 1;
    padding-right: 0px; /* make 20px after profile pic added */
  }
  
  .name {
    font-weight: 700 !important;
    margin-bottom: 8px !important;
    font-size: 80px !important;
  }
  
  .job-title {
    font-weight: 500 !important;
    font-size: 50px !important;
    margin-bottom: 8px !important;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
  }
  
  .cursor {
    display: inline-block;
    position: relative;
    margin-left: 4px;
    animation: blink 0.8s infinite;
  }
  
  .cursor::before,
.cursor::after {
  content: '';
  position: absolute;
  height: 8px; /* Adjust the height as needed */
  width: 2px; /* Adjust the width as needed */
  background-color: #000; /* Adjust the color as needed */
}

.cursor::before {
  top: -8px; /* Adjust the top position to create a larger top side */
}

.cursor::after {
  bottom: -8px; /* Adjust the bottom position to create a larger bottom side */
}
  
@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.description {
  margin-bottom: 8px !important;
  font-size: 22px !important;
}

.right-column {
  width: 45%;
  display: none; /* make flex after profile picture added*/
  align-items: flex-end !important;
  justify-content: flex-end !important;
}

.avatar {
  width: 500px !important;
  height: 500px !important;
}

.avatar-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

/* xs to sm */
@media screen and (max-width: 600px) {
  .name {
    font-size: 60px !important;
  }
  .avatar {
    width: 150px !important;
    height: 150px !important;
  }
  .profile-container {
    flex-direction: column;
    min-width: 90vw;
  }
  .job-title {
    font-size: 36px !important;
  }
  .description {
    font-size: 18px !important;
  }
  .left-column {
    padding-right: 0px;
    padding-bottom: 20px;
  }
  .right-column {
    width: 90%;
    display: none;
    align-items: center !important;
    justify-content: center !important;
  }
}

/* sm to md */
@media screen and (max-width: 900px) and (min-width: 600px) {
  .name {
    font-size: 68px !important;
  }
  .avatar {
    width: 150px !important;
    height: 150px !important;
  }
  .profile-container {
    flex-direction: column;
    min-width: 90vw;
  }
  .job-title {
    font-size: 40px !important;
  }
  .description {
    font-size: 22px !important;
  }
  .left-column {
    padding-right: 0px;
    padding-bottom: 20px;
  }
  .right-column {
    width: 90%;
    display: none;
    align-items: center !important;
    justify-content: center !important;
  }
}
/* md to lg */
@media screen and (max-width: 1200px) and (min-width: 900px) {
  .name {
    font-size: 80px !important;
  }
  .avatar {
    width: 150px !important;
    height: 150px !important;
  }
  .profile-container {
    flex-direction: column;
    min-width: 90vw;
  }
  .job-title {
    font-size: 48px !important;
  }
  .description {
    font-size: 26px !important;
  }
  .left-column {
    padding-right: 0px;
    padding-bottom: 20px;
  }
  .right-column {
    width: 90%;
    display: none;
    align-items: center !important;
    justify-content: center !important;
  }
}
@media screen and (max-width: 1370px) and (min-width: 1200px) {
  .name {
    font-size: 80px !important;
  }
  .profile-container {
    min-width: 90vw;
  }
  .job-title {
    font-size: 48px !important;
  }
  .description {
    font-size: 30px !important;
  }

  /* remove after profile picture added */
  .left-column {
    padding-right: 0px;
    padding-bottom: 20px;
  }
  .right-column {
    width: 90%;
    display: none;
    align-items: center !important;
    justify-content: center !important;
  }
}
/* @media screen and (max-width: 1536px) and (min-width: 1200px) {

} */
  